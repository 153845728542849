<template>
  <div class="row mx-0 mb-1">
    <div class="col-12 p-0" v-if="label">
      <div class="form-label" v-html="label"></div>
    </div>
    <div class="col-12 form-control holder" :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}">
      <select
        v-model="value"
        class="form-control form-control-inner w-100 medium"
        :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"
        :disabled="disabled"
      >
        <option value="" disabled v-if="placeholder">{{placeholder}}</option>
        <option :value="option" v-for="option in options" :key="option" :selected="modelValue === option ? 'selected' : ''" class="normal-text-dark">
          <span v-if="subDisplayValue">{{option[displayValue][subDisplayValue]}}</span>
          <span v-else>{{option[displayValue]}}</span>
        </option>
      </select>
      <div class="dropdown_icon" :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"><ios-arrow-down-icon /></div>
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  name: 'SelectProject',
  props: ['placeholder', 'options', 'displayValue', 'subDisplayValue', 'modelValue', 'errors', 'hasErrors', 'size', 'color', 'disabled', 'label'],
  // props: {
  //   inputValue: String,
  //   placeholder: String,
  //   options: Array,
  //   displayValue: String,
  //   modelValue: String,
  //   errors: Array,
  //   hasErrors: Number,
  //   size: String,
  //   color: String
  // },
  emits: ['update:modelValue'],
  mounted () {
    if (this.options && !this.modelValue) {
      this.value = this.options[0]
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control:disabled {
    opacity: 0.5;
    background-color: inherit;
  }
  .form-control:focus {
    color: var(--green-color-light);
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--green-color-light);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
    z-index: 2;
  }
  .holder {
    position: relative;
  }
  .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 0.7rem;
    right: 1rem;
    z-index: 1;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control-inner {
    background-color: transparent;
    position: relative;
    border-radius: 1rem;
    border: none;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 1rem;
  }
  .form-control.small {
    border: 1px solid var(--green-color-dark);
    font-size: 0.8rem;
    height: calc(2rem + 2px);
    border-radius: 2rem;
  }
  .form-control-inner.small {
    padding: 0 0.5rem;
    height: 2rem;
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  .uppercase {
    text-transform: capitalize;
  }
  .abs {
    position: absolute;
    top: 0;
    left: 0.75rem;
    width: 100%;
  }
</style>
